<mat-drawer-container class="flex flex-row h-full surface-ch">
    <mat-drawer #drawer [mode]="'side'" [opened]="true" class="w-[80px] surface-ch">
        <div class="flex flex-col gap-4 items-center justify-start pt-3" style="height: calc(100% - 36px)"
             tabindex="-1">

            <button mat-icon-button color="primary" (click)="drawer.toggle()">
                <mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
            </button>

            <span class="h-[16px]"></span>

            @if (profile?.schedulerModule) {

                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Admission')"
                            mat-button class="vertical-button"
                            routerLink="/scheduler"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                [fontIcon]="rla.isActive ?'mdi-folder-plus': 'mdi-folder-plus-outline'"
                                fontSet="mdi"></mat-icon>

                    </button>

                    <span class="navigation-item">{{ 'SCHEDULE.NAME' | translate }}</span>
                </div>

            }

            @if (profile?.workflowModule) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Workflow')"
                            mat-button class="vertical-button"
                            routerLink="/workflow"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                [fontIcon]="rla.isActive ?'mdi-clipboard-flow': 'mdi-clipboard-flow-outline'"
                                fontSet="mdi"></mat-icon>
                    </button>
                    <span class="navigation-item">{{ 'WORKFLOW.NAME' | translate }}</span>
                </div>

            }

            @if (profile?.reportingModule) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Reporting')"
                            mat-button class="vertical-button"
                            routerLink="/reporting"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                [fontIcon]="rla.isActive ?'mdi-folder-open': 'mdi-folder-open-outline'"
                                fontSet="mdi"></mat-icon>
                    </button>
                    <span class="navigation-item">{{ 'REPORTING.NAME' | translate }}</span>
                </div>

            }

            @if (profile?.patientModule) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Patients')"
                            mat-button class="vertical-button"
                            routerLink="/patients"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                [fontIcon]="rla.isActive ?'mdi-folder-account': 'mdi-folder-account-outline'"
                                fontSet="mdi"></mat-icon>
                    </button>
                    <span class="navigation-item">{{ 'PATIENTS' | translate }}</span>
                </div>
            }

            @if (profile?.physicianModule) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Physicians')"
                            mat-button class="vertical-button"
                            routerLink="/physicians"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                [fontIcon]="rla.isActive ?'mdi-account-supervisor': 'mdi-account-supervisor-outline'"
                                fontSet="mdi"></mat-icon>
                    </button>
                    <span class="navigation-item">{{ 'Physicians' | translate }}</span>
                </div>

            }

            @if (profile?.statisticModule) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Statistic')"
                            mat-button class="vertical-button"
                            routerLink="/statistic"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                [fontIcon]="rla.isActive ?'mdi-chart-pie': 'mdi-chart-pie-outline'"
                                fontSet="mdi"></mat-icon>
                    </button>
                    <span class="navigation-item">{{ 'STATISTIC' | translate }}</span>
                </div>

            }



            <span class="flex-grow"></span>
            @if (profile) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Messaging'); totalNotifs = null"
                            mat-button class="vertical-button"
                            routerLink="/messaging"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                class="chat-icon"
                                [matBadge]="totalNotifs"
                                aria-hidden="false"
                                [fontIcon]="rla.isActive ? 'mdi-message-settings': 'mdi-message-settings-outline'"
                                fontSet="mdi"
                                matBadgeColor="warn"
                                matBadgeSize="small"></mat-icon>
                    </button>

                    <span class="navigation-item">{{ 'MESSAGING' | translate }}</span>
                </div>

            }
            @if (profile?.loggingModule) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Logs')"
                            mat-button class="vertical-button"
                            routerLink="/logs"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                class="logs-icon"
                                [fontIcon]="rla.isActive ? 'mdi-database-clock': 'mdi-database-clock-outline'"
                                fontSet="mdi"></mat-icon>
                    </button>
                    <span class="navigation-item">{{ 'LOGS' | translate }}</span>
                </div>

            }

            @if (profile?.settingModule) {
                <div class="flex flex-col gap-1.5 items-center justify-center text-center">
                    <button
                            (click)="setTitle('Settings')"
                            mat-button class="vertical-button"
                            routerLink="/setting"
                            routerLinkActive="active"
                            #rla="routerLinkActive"
                            tabindex="-1">
                        <mat-icon
                                class="setting-icon"
                                [fontIcon]="rla.isActive ? 'mdi-cog': 'mdi-cog-outline'"
                                fontSet="mdi"></mat-icon>
                    </button>
                    <span class="navigation-item">{{ 'SETTINGS' | translate }}</span>
                </div>
            }

        </div>

    </mat-drawer>
    <mat-drawer-content class="flex-grow surface-cl rounded-bl-2xl rounded-tl-3xl overflow-hidden flex flex-col">


        <div class="flex flex-row px-5 py-1.5 items-center justify-start gap-1.5 h-[42px]">
            @if (!drawer.opened) {
                <button mat-icon-button color="primary" (click)="drawer.toggle()">
                    <mat-icon fontSet="mdi" fontIcon="mdi-menu"></mat-icon>
                </button>
            }

            <img
                    [alt]="logo"
                    class="app-angular-logo"
                    src="../assets/images/{{ logo }}"/>


            <span class="flex-grow"></span>

            <ft-advanced-search [config]="searchConfig"></ft-advanced-search>

            <span class="flex-grow"></span>

            <a
                    mat-icon-button
                    [matTooltip]="'BARCODE_SEARCH' | translate"
                    tabindex="-1"
                    (click)="searchBarcode()">
                <mat-icon
                        class="barcode-icon"
                        fontSet="mdi"
                        fontIcon="mdi-barcode-scan"></mat-icon>
            </a>

            <a
                    (click)="toggleFullScreen()"
                    mat-icon-button
                    matTooltip="{{ 'TOGGLE_FULLSCREEN' | translate }}"
                    tabindex="-1">
                <mat-icon
                        fontIcon="{{
						isFullscreen() ? 'mdi-fullscreen-exit' : 'mdi-fullscreen'
					}}"
                        fontSet="mdi"></mat-icon>
            </a>

            <button mat-icon-button [matMenuTriggerFor]="profileMenu">
                <img class="profile-icon" height="44px" src="assets/images/man.png" alt="Profile">
            </button>
            <mat-menu #profileMenu="matMenu">


                <div class="flex flex-col gap-1.5 justify-evenly items-center">

                    <div class="flex flex-col gap-2 justify-start items-center">
                        <div class="h-[74px]"><img style="height: inherit; border-radius: 50%"
                                                   src="assets/images/man.png" alt="Profile"></div>
                        <b>{{ userName }}</b>


                        <div class="flex flex-row gap-sm items-end justify-center">
                            <button matTooltip="{{ 'PROFILE' | translate }}"
                                    (click)="updateUser()"
                                    mat-icon-button
                                    tabindex="-1">
                                <mat-icon
                                        class="user-setting-icon"
                                        fontIcon="mdi-account-cog-outline"
                                        fontSet="mdi"></mat-icon>
                            </button>

                            <button
                                    (click)="$event.preventDefault();$event.stopImmediatePropagation();toggleTheme()"
                                    mat-icon-button
                                    [matTooltip]="(darkTheme ? 'LIGHT_MODE' : 'DARK_MODE') | translate"
                                    tabindex="-1">
                                <mat-icon
                                        class="theme-icon"
                                        [fontIcon]="darkTheme ? 'mdi-white-balance-sunny' : 'mdi-weather-night'"
                                        fontSet="mdi"></mat-icon>
                            </button>

                            <button
                                    (click)="$event.preventDefault();$event.stopImmediatePropagation();showAppInfo()"
                                    mat-icon-button
                                    [matTooltip]="'APP_INFO' | translate"
                                    tabindex="-1">
                                <mat-icon
                                        class="about-icon"
                                        fontIcon="mdi-information"
                                        fontSet="mdi"
                                        style="color: #00bcd4"></mat-icon>
                            </button>
                        </div>

                    </div>


                    <button
                            class="language-menu-button"
                            [matMenuTriggerFor]="languageMenu"
                            mat-menu-item>
                        <mat-icon fontIcon="mdi-translate" fontSet="mdi"></mat-icon>
                        {{ 'shared.uiLanguage' | translate }}
                    </button>




                    @if (profile?.admin) {
                        <mat-divider class="w-full"></mat-divider>
                        <button mat-menu-item (click)="logoutOthers()">
                            <mat-icon fontSet="mdi" fontIcon="mdi-power-plug-off-outline"></mat-icon>
                            {{ 'LOGOUT_OTHERS' | translate }}
                        </button>
                        <button mat-menu-item (click)="logoutMySessions()">
                            <mat-icon fontSet="mdi" fontIcon="mdi-power-settings"></mat-icon>
                            {{ 'LOGOUT_MINE' | translate }}
                        </button>
                    }

                    <mat-divider class="w-full"></mat-divider>
                    <a
                            (click)="logout()"
                            class="logout"
                            [ngStyle]="{ marginRight: profile?.admin ? '0.5rem' : '0' }"
                            mat-menu-item
                            tabindex="-1">
                        <mat-icon
                                class="logout-icon"
                                fontIcon="mdi-power"
                                fontSet="mdi"
                                style="color: #f44336"></mat-icon>
                        {{ 'LOGOUT' | translate }}
                    </a>


                </div>

            </mat-menu>

            <mat-menu #languageMenu="matMenu">
                <button mat-menu-item (click)="changeUILanguage('en')">En</button>
                <button mat-menu-item (click)="changeUILanguage('fr')">Fr</button>
                <button mat-menu-item (click)="changeUILanguage('nl')">Nl</button>
            </mat-menu>
        </div>

        <div class="p-2 overflow-hidden flex-grow">
            <router-outlet></router-outlet>
        </div>

        <audio
            #sound_player
            style="position: fixed; visibility: hidden"
            class="sound-player"
            src="assets/newmessage_sound.mp3"></audio>
    </mat-drawer-content>
</mat-drawer-container>
