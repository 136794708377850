import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SettingComponent } from "./setting.component";
import { SettingService } from "./setting.service";
import { SettingRoutingModule } from "./setting-routing.module";
import { AetEditComponent } from "./aet-setting/aet-edit/aet-edit.component";
import { FireSharedModule, ReporterComponent, SharedService } from "../shared";
import { UserEditComponent } from "./users-setting/user-edit/user-edit.component";
import { ProfileEditComponent } from "./profile-setting/profile-edit/profile-edit.component";
import { ColorPickerModule } from "ngx-color-picker";
import { ProcedureCodeEditComponent } from "./procedure-code-setting/procedure-code-edit/procedure-code-edit.component";
import { PathologyEditComponent } from "./pathology-setting/pathology-edit/pathology-edit.component";
import { AetSettingComponent } from "./aet-setting/aet-setting.component";
import { ProcedureCodeSettingComponent } from "./procedure-code-setting/procedure-code-setting.component";
import { UsersSettingComponent } from "./users-setting/users-setting.component";
import { ProfileSettingComponent } from "./profile-setting/profile-setting.component";
import { ReportTemplateSettingComponent } from "./report-template-setting/report-template-setting.component";
import { ModalitySettingComponent } from "./modality-setting/modality-setting.component";
import { ImagingCenterSettingComponent } from "./imaging-center-setting/imaging-center-setting.component";
import { GeneralSettingComponent } from "./general-setting/general-setting.component";
import { PrioritySettingComponent } from "./priority-setting/priority-setting.component";
import { StaticResourceEditComponent } from "./static-resource-edit/static-resource-edit.component";
import { UnavailabilityComponent } from "./unavailability/unavailability.component";
import { ReasonForExamComponent } from "./reason-for-exam/reason-for-exam.component";
import { RoomSettingComponent } from "./room-setting/room-setting.component";
import { PathologySettingComponent } from "./pathology-setting/pathology-setting.component";
import { BillingCodesComponent } from "./billing-codes/billing-codes.component";
import { BillingCodeEditComponent } from "./billing-codes/billing-code-edit/billing-code-edit.component";
import { StaffContractComponent } from "./staff-contract/staff-contract.component";
import { EditStaffContractComponent } from "./staff-contract/edit-staff-contract/edit-staff-contract.component";
import { ViewersComponent } from "./viewers/viewers.component";
import { BoxEditComponent } from "./box-edit/box-edit.component";
import { PrintingModelsComponent } from "./printing-models/printing-models.component";
import { SmsTemplatesComponent } from "./sms-templates/sms-templates.component";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DefaultValuesComponent } from "./default-values/default-values.component";

import { PeersSettingComponent } from "./peers-setting/peers-setting.component";
import { LabelSettingComponent } from "./label-setting/label-setting.component";
import { FormlyModule } from "@ngx-formly/core";
import { FormlyMaterialModule } from "@ngx-formly/material";
import { FormsBuilderComponent } from "./forms-builder/forms-builder.component";

@NgModule({
  imports: [
    CommonModule,
    ColorPickerModule,
    SettingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTooltipModule,
    MatListModule,
    MatToolbarModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatSelectModule,
    MatOptionModule,
    MatSidenavModule,
    FireSharedModule,
    MatExpansionModule,
    ClipboardModule,
    FormlyModule.forRoot({}),
    FormlyMaterialModule,
    ReporterComponent,
  ],
  declarations: [
    SettingComponent,
    AetSettingComponent,
    ProcedureCodeSettingComponent,
    UsersSettingComponent,
    ProfileSettingComponent,
    ReportTemplateSettingComponent,
    GeneralSettingComponent,
    ImagingCenterSettingComponent,
    PrioritySettingComponent,
    ModalitySettingComponent,
    AetEditComponent,
    StaticResourceEditComponent,
    UserEditComponent,
    ProfileEditComponent,
    UnavailabilityComponent,
    ProcedureCodeEditComponent,
    ReasonForExamComponent,
    RoomSettingComponent,
    PathologySettingComponent,
    PathologyEditComponent,
    BillingCodesComponent,
    BillingCodeEditComponent,
    StaffContractComponent,
    EditStaffContractComponent,
    ViewersComponent,
    BoxEditComponent,
    PrintingModelsComponent,
    SmsTemplatesComponent,
    DefaultValuesComponent,
    PeersSettingComponent,
    LabelSettingComponent,
    FormsBuilderComponent,
  ],
  providers: [SettingService, SharedService],
})
export class SettingModule {}
