<mat-card class="h-[150px] w-[360px] p-3 flex flex-col gap-1 justify-between">


    @if (workflowItem(); as item) {
        <div class="flex flex-row flex-nowrap gap-2 justify-between items-start">
            <div class="flex flex-col flex-grow">
                <span class="text-lg font-semibold overflow-hidden text-nowrap text-ellipsis max-w-52">
                    {{item.procedureCode}}</span>
                <span class="opacity-90 font-medium">{{ item.accessionNumber }}</span>
            </div>

            <div class="flex flex-col">
                <div class="flex flex-row items-center">
                    <mat-icon class="opacity-90" fontIcon="mdi-clock" fontSet="mdi"></mat-icon>
                    <span class="font-medium"> {{item.examinationStarted | date: 'HH:mm'}} - {{item.examinationFinished | date: 'HH:mm'}} </span>
                </div>
                <div class="flex flex-row items-center">
                    <mat-icon class="opacity-90" fontIcon="mdi-credit-card" fontSet="mdi"></mat-icon>
                    <span class="font-medium text-base">{{item.totalAmount}} {{ _config.currencyFormat }}</span>
                </div>
            </div>
        </div>

        <div class="flex flex-col">
            <div class="flex flex-row items-center">
                <mat-icon fontIcon="mdi-doctor" fontSet="mdi"></mat-icon>
                <span>{{ item.performingPhysician | titlecase }}</span>
            </div>
            <div class="flex flex-row items-center">
                <span class="opacity-90 font-medium">{{ 'TECHNICIAN' | translate }}: </span>
                <span>{{ item.technician | titlecase }}</span>
            </div>
        </div>



        <div class="flex flex-row justify-between items-center w-full font-semibold">

            <span class="flex flex-row justify-center items-center outer-badge">{{ item.modality }}</span>

            <div class="flex flex-row justify-center items-center outer-badge">
                <mat-icon fontIcon="mdi-file-edit-outline" fontSet="mdi"></mat-icon>
                <span class="inner-badge light-color" [style.background-color]="getReportIconColor(item.reportingStatus)">{{item.reportingStatus | translate}}</span>
            </div>

            <div class="flex flex-row justify-center items-center outer-badge">
                <mat-icon fontIcon="mdi-account-injury-outline" fontSet="mdi"></mat-icon>
                <span class="inner-badge light-color" [style.background-color]="getIconColor(item.patientStatus)">{{ item.patientStatus | translate }}</span>
            </div>
        </div>
    } @else {
        <div>Loading...</div>
    }

</mat-card>
