import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
	selector: 'ft-player',
	template: `
		<div class="audio-player">
			<audio #audioPlayer [src]="currentSongUrl" controls></audio>

			<div class="controls">
				<button (click)="play()">Play</button>
				<button (click)="pause()">Pause</button>
				<button (click)="stop()">Stop</button>

				<input
					type="range"
					min="0"
					max="1"
					step="0.01"
					value="0"
					(input)="seek($event.target['value'])" />
			</div>
		</div>
	`,
	standalone: true,
	styles: `
		.audio-player {
			display: flex;
			flex-direction: column;
			width: 300px;
			margin: 0 auto;
		}

		.controls {
			display: flex;
			justify-content: space-between;
			margin-top: 10px;
		}
	`,
})
export class PlayerComponent implements OnInit {
	@Input() currentSongUrl: string;
	@ViewChild('audioPlayer') audioPlayer: HTMLAudioElement;

	ngOnInit(): void {
		this.audioPlayer = document.getElementById(
			'audioPlayer'
		) as HTMLAudioElement;
	}

	play(): void {
		this.audioPlayer.play();
	}

	pause(): void {
		this.audioPlayer.pause();
	}

	stop(): void {
		this.audioPlayer.currentTime = 0;
		this.audioPlayer.pause();
	}

	seek(value: number): void {
		this.audioPlayer.currentTime = value;
	}
}
