import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkflowComponent } from './workflow.component';
import { WorkflowService } from './workflow.service';
import { WorkflowRoutingModule } from './workflow-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FireSharedModule } from '../shared';
import { MessagingModule } from '../messaging';
import {ExamCardComponent} from "../shared/exam-card/exam-card.component";

@NgModule({
    imports: [
        CommonModule,
        WorkflowRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FireSharedModule,
        MatToolbarModule,
        MatCardModule,
        MatTooltipModule,
        MatGridListModule,
        MessagingModule,
        ExamCardComponent,
    ],
	declarations: [WorkflowComponent],
	providers: [WorkflowService],
})
export class WorkflowModule {}
