import {
    Component,
    ElementRef,
    isDevMode,
    viewChild, inject, DestroyRef,
} from '@angular/core';
import {FullScreen, Profile, SearchConfig, WsMessage} from './model';
import {Title} from '@angular/platform-browser';
import {AuthService} from './auth';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {getAppType, LocalStorageService, UserUpdateComponent} from './shared';
import {AppInfoComponent} from './app-info/app-info.component';
import {Platform} from '@angular/cdk/platform';
import {WsService} from './ws.service';
import {Message, MessagePopupComponent} from './messaging';
import {NavigationEnd, Router} from '@angular/router';
import {bufferTime, filter, map} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SchedulerService} from './scheduler/scheduler.service';
import {SettingService} from './setting/setting.service';
import {BarcodeSearchComponent} from './shared/barcode-search/barcode-search.component';
import {AppConfigService} from './app-config.service';
import {logProductName} from './utils';
import {SearchService} from "./shared/advanced-search/search.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
    selector: 'ft-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent {
    private titleService = inject(Title);
    private translate = inject(TranslateService);
    private _authService = inject(AuthService);
    private dialog = inject(MatDialog);
    private _platform = inject(Platform);
    private _ws = inject(WsService);
    private _router = inject(Router);
    private _localStorage = inject(LocalStorageService);
    private _scheduler = inject(SchedulerService);
    private _setting = inject(SettingService);
    private _config = inject(AppConfigService);
    private _snack = inject(MatSnackBar);
    private _searchService = inject(SearchService);
    private _destroyRef = inject(DestroyRef);

    username: string;
    profile: Profile;
    user: any;
    readonly logo: string;
    public mobile: boolean;
    public totalNotifs: number = null;

    public soundPlayer = viewChild<ElementRef>('sound_player');

    private curl: any;
    public readonly isDev: boolean;
    public readonly uiLanguage: string;
    searchConfig!: SearchConfig;

    private localStorage = inject(LocalStorageService);

    public get userName(): string {
        return this.user
            ? this.user.lastName + ' ' + this.user.firstName
            : 'Anonymous';
    }

    constructor() {

        this._searchService.newSearchView.set(this.localStorage.getItem('new-search-view') || true);
        this._searchService.searchObs.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(config => this.searchConfig = config);

        this.logo = this._config.logo;
        const appType = getAppType(this.logo);
        logProductName(appType);

        this.setTitle(appType === 'cvis' ? 'FireCVIS' : 'FireRIS');

        this.isDev = isDevMode();


        this._scheduler.scanIdCard.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(_ => {
            const eid_url = localStorage.getItem('eid_api_url');
            const url =
                JSON.parse(eid_url) + `/eid/scan_eid/${this.user.id}`;
            console.log(
                `%c${url}`,
                'color:#8888ff;font-weight:bold;border:2px solid grey;padding:8px;border-radius:6px;'
            );

            window.open(url);
        });

        this.mobile = this._platform.IOS || this._platform.ANDROID;

        this.user = this._localStorage.getItem('user');
        this.profile = this.user.profile;
        this.uiLanguage = this._config.appLang;

        const chat_topic = 'chat/' + this.user.id;


        this._ws.observeTopic(chat_topic)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(data => {
                if (data) {
                    if (data.topic !== chat_topic) return;

                    const message = JSON.parse(data.response) as WsMessage;
                    if ('newMessage' === message.text) {
                        const msg = message.data as Message;
                        if (
                            msg.sender.id !== this.user.id &&
                            !this.curl.startsWith('/messaging')
                        ) {
                            this.totalNotifs += 1;
                            this.playSound();
                            this.showMessagePopup(msg);
                        }
                    }

                    this._ws.chatUpdate.next(message);
                }
            });


        this._ws
            .observeTopic('logout')
            .pipe(
                bufferTime(5000),
                map(arr => arr[arr.length - 1]),
                takeUntilDestroyed(this._destroyRef)
            )
            .subscribe(message => {
                if (!message || message.topic !== 'logout') return;

                const msg = JSON.parse(message.response) as WsMessage;
                const token = localStorage.getItem('access_token');
                const user = this.user?.username;

                if (msg.text !== token) {
                    if (msg.data) {
                        if (msg.data === user) this.logout();
                        else return;
                    }

                    this.logout();
                }
            });

        const generalSetting = this._config.generalSetting;
        if (
            generalSetting.idScannerConfigured &&
            generalSetting.scannerIdUrl?.startsWith('beid')
        ) {
            const eid_card_topic = 'eid-reader/' + this.user.id;
            this._ws.observeExternalTopic(eid_card_topic)
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(data => {
                    if (data) {
                        const message = JSON.parse(data.response) as WsMessage;
                        const msg = message.data;
                        this._scheduler.beidCardData.next(msg);
                    }
                });
        }


        this._router.events
            .pipe(filter(e1 => e1 instanceof NavigationEnd),
                takeUntilDestroyed(this._destroyRef))
            .subscribe(e2 => (this.curl = e2['url']));


        this._ws.soundPlayer.asObservable()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(value => {
                if (value) this.playSound();
            });
    }


    public changeUILanguage(lang: string) {
        this._setting
            .changeUILanguage(lang)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => location.reload());
    }

    private playSound() {
        const sound = this.soundPlayer();
        if (sound) sound.nativeElement.play();
    }

    showAppInfo = () => this.dialog.open(AppInfoComponent, {width: '400px'});

    public get darkTheme(): boolean {
        return this._localStorage.getItem('theme') === 'dark';
    }

    public toggleTheme(): void {
        const bodyClassList = document.getElementById('ft-body').classList;
        bodyClassList.toggle('dark-theme');
        if (bodyClassList.contains('dark-theme'))
            this._localStorage.setItem('theme', 'dark');
        else this._localStorage.setItem('theme', 'light');
    }

    private showMessagePopup(message: Message): void {
        this._snack
            .openFromComponent(MessagePopupComponent, {
                horizontalPosition: 'end',
                verticalPosition: 'bottom',
                data: message,
                duration: 10000,
            })
            .afterDismissed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(value => {
                if (value.dismissedByAction) {
                    this.totalNotifs = null;
                    this._router.navigate(['/messaging'], {
                        queryParams: {roomId: message.roomId},
                    });
                }
            });
    }

    public toggleFullScreen(): void {
        if (this.isFullscreen()) FullScreen.cancelFullScreen()
        else FullScreen.goFullScreen();
    }

    public isFullscreen() {
        return document.fullscreenElement != null;
    }

    public setTitle(newTitle: string): void {
        this.titleService.setTitle(this.translate.instant(newTitle));
    }

    public logout(): void {
        this._authService.logout()
            .pipe(
                takeUntilDestroyed(this._destroyRef))
            .subscribe(() => this._router.navigate(['/login']),);
    }

    updateUser() {
        this.dialog
            .open(UserUpdateComponent, {data: this.user})
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(res => {
                if (res) this.logout();
            });
    }

    searchBarcode() {
        this.dialog.open(BarcodeSearchComponent, {
            minWidth: '100vw',
            minHeight: '100vh',
            panelClass: 'barcode-panel',
        });
    }

    logoutOthers() {
        this._authService
            .logoutOthers(localStorage.getItem('access_token'), true)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe();
    }

    logoutMySessions() {
        this._authService
            .logoutOthers(localStorage.getItem('access_token'))
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe();
    }
}
