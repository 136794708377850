@if (license) {
<div class="fx-layout-column fx-content-space-between fx-items-center fx-fill">
  <img [width]="300" alt="Logo" src="assets/images/firethunder.png" />

  <mat-divider class="info-divider"></mat-divider>

  <div class="license-title">{{ "LICENSE_ATTRIBUTED_TO" | translate }}:</div>

  <mat-divider class="info-divider"></mat-divider>

  <div class="client">
    {{ license.clientName }}
  </div>

  <mat-divider class="info-divider"></mat-divider>

	<div class="contacts">
		<mat-list>
			<h3 matSubheader style="height: 12px; font-weight: bold">Products</h3>
			@for (product of products; track $index) {
				<mat-list-item>
					<b matListItemTitle>{{ product.name }}</b>
					<p matListItemMeta>expires: {{product.expires}}</p>
				</mat-list-item>
			}
		</mat-list>
	</div>

  <mat-divider class="info-divider"></mat-divider>

  <div class="contacts">
    <mat-list>
      <h3 matSubheader style="height: 26px; font-weight: bold">Contacts</h3>
      @for (contact of toList(license.contacts); track $index) {
      <mat-list-item
        ><a [href]="'tel:' + phone(contact)">{{ contact }}</a></mat-list-item
      >
      }
    </mat-list>
  </div>

  <span class="fx-grow-1"></span>
  <mat-divider style="width: 100%"></mat-divider>
  <div class="version">
    {{ license.version }} &copy; {{ license.company }}
    {{ license.year }}
  </div>
</div>
} @else {
<div>No license.</div>
}
