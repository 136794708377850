<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ itemType | uppercase | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div class="fx-padding-16">
  <form [formGroup]="staticForm" class="fx-layout-column">
    <mat-form-field>
      <mat-label>{{ "VALUE" | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'VALUE' | translate }}"
        formControlName="value"
      />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ "DESCRIPTION" | translate }}</mat-label>
      <textarea
        matInput
        placeholder="{{ 'DESCRIPTION' | translate }}"
        formControlName="description"
      ></textarea>
    </mat-form-field>

    @if (itemType === 'modality') {
    <mat-form-field [formGroup]="aetForm">
      <mat-label>{{ "DEFAULTAETITLE" | translate }}</mat-label>
      <mat-select
        placeholder="{{ 'DEFAULTAETITLE' | translate }}"
        formControlName="title"
      >
        @for (item of aets; track $index) {
        <mat-option [value]="item.title">
          {{ item.title }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
    }

    <div class="ft-color">
      <div>{{ "COLOR" | translate }}:</div>
      <div
        class="color-area"
        [(colorPicker)]="color"
        [style.background]="color"
      ></div>
    </div>
  </form>
</div>
<div matDialogActions align="end">
  <button mat-raised-button color="warn" [mat-dialog-close]="null">
    {{ "CANCEL" | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onSave(staticForm.value)"
    [disabled]="staticForm.invalid"
  >
    {{ "SAVE" | translate }}
  </button>
</div>
