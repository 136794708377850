

    <div class="flex flex-row items-center justify-start gap-1.5 advanced-search-input" [class.expanded]="expanded()" [class.hidden]="hidden()">
        <button mat-icon-button>
            <mat-icon class="flex-shrink-0" fontIcon="mdi-magnify" fontSet="mdi"></mat-icon>
        </button>

        <div [contentEditable]="true" id="editableDiv" #searchField data-placeholder="Rechercher ..."></div>

        @if (dirty()) {
            <button (click)="reset()" mat-icon-button>
                <mat-icon class="flex-shrink-0" fontIcon="mdi-close" fontSet="mdi"></mat-icon>
            </button>
        }
        @if (template && expandable) {
            <button mat-icon-button (click)="togglePanel()">
                <mat-icon class="flex-shrink-0" fontIcon="mdi-tune" fontSet="mdi"></mat-icon>
            </button>
        }
    </div>


@if (expanded()) {
    <div class="portal-outlet">
        <ng-container [ngTemplateOutlet]="template || defaultTemplate"></ng-container>
        <ng-template #defaultTemplate>
            No provided template
        </ng-template>
    </div>

    <div class="overlay" (click)="closePanel()"></div>
}
