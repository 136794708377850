import {Component, inject, input} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatCard} from "@angular/material/card";
import {WorkflowItem} from "../../model";
import {DatePipe, TitleCasePipe} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {getIconColor, getReportIconColor} from "../../utils";
import {AppConfigService} from "../../app-config.service";

@Component({
  selector: 'ft-exam-card',
  standalone: true,
  imports: [
    MatIcon,
    MatCard,
    DatePipe,
    TitleCasePipe,
    TranslateModule
  ],
  templateUrl: './exam-card.component.html',
  styleUrl: './exam-card.component.scss'
})
export class ExamCardComponent {

  workflowItem = input.required<WorkflowItem>();

  public _config = inject(AppConfigService);

  protected readonly getReportIconColor = getReportIconColor;
  protected readonly getIconColor = getIconColor;
}
