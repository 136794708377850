import {
    AfterViewInit,
    Component,
    effect,
    ElementRef,
    inject,
    input,
    signal,
    TemplateRef,
    viewChild
} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {SearchConfig} from "../../model";
import {CdkPortalOutlet} from "@angular/cdk/portal";
import {NgTemplateOutlet} from "@angular/common";
import {SearchService} from "./search.service";
import {TranslateService} from "@ngx-translate/core";
import {get} from 'lodash';
import {fromEvent} from "rxjs";
import {debounceTime, map} from "rxjs/operators";
import {toObservable} from "@angular/core/rxjs-interop";

@Component({
    selector: 'ft-advanced-search',
    standalone: true,
    imports: [
        MatIcon,
        MatIconButton,
        CdkPortalOutlet,
        NgTemplateOutlet
    ],
    templateUrl: './advanced-search.component.html',
    styleUrl: './advanced-search.component.scss'
})
export class AdvancedSearchComponent implements AfterViewInit {
    dirty = signal(false);
    searchFormInput = viewChild<ElementRef>('searchField');
    config = input.required<SearchConfig>();

    private _searchService = inject(SearchService);
    private _translate = inject(TranslateService);
    expanded = signal(false);

    placeholder = signal('Search');
    template!: TemplateRef<any>;
    expandable: boolean = true;
    hidden = signal(true);
    hiddenObs = toObservable(this.hidden);

    private editableDiv!: HTMLElement;

    constructor() {
        effect(() => {
            const _cfg = this.config();
            if (_cfg) {
                this.hidden.set(get(_cfg, 'hidden', true));
                this.expandable = get(_cfg, 'expandable', true);
                this.template = _cfg.template;
                this.placeholder.set(this._translate.instant(_cfg.placeholder));

                this.updatePlaceholder();
            }
        }, {allowSignalWrites: true});
    }

    togglePanel() {
        this.expanded.update((value: boolean) => !value);
    }

    reset() {
        this.searchFormInput().nativeElement.innerHTML = '';
        this.dirty.set(false);
        this.searchFormInput().nativeElement.focus();
        this._searchService.genericSearch.set('');
    }

    private updatePlaceholder() {
        this.editableDiv = document.getElementById("editableDiv");
        this.editableDiv?.setAttribute('data-placeholder', this.placeholder());
    }

    ngAfterViewInit(): void {
        this.hiddenObs.subscribe(hidden => {
            if (!hidden) fromEvent(this.searchFormInput().nativeElement, 'keyup')
                .pipe(debounceTime(300), map((e: any) => e.target.textContent))
                .subscribe(value => this._searchService.genericSearch.set(value));

        });
        this.updatePlaceholder();

        const self = this;
        this.editableDiv?.addEventListener("focus", function () {
            if (self.editableDiv.innerHTML.trim() === '') {
                self.editableDiv.innerHTML = '';
            }
        });

        this.editableDiv?.addEventListener("blur", function () {
            if (self.editableDiv.innerHTML.trim() === '') {
                self.editableDiv.innerHTML = '';
            }
        });

        this.editableDiv?.addEventListener("input", function () {
            if (self.editableDiv.innerHTML.trim() === '') {
                self.editableDiv.innerHTML = '';
                self.dirty.set(false);
            } else self.dirty.set(true);
        });
    }

    closePanel() {
        this.togglePanel()
    }
}
