import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared';
import { AppConfigService } from '../app-config.service';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';

@Component({
	selector: 'ft-app-info',
	templateUrl: './app-info.component.html',
	standalone: true,
	imports: [MatDividerModule, TranslateModule, MatListModule],
	styleUrl: './app-info.component.scss',
})
export class AppInfoComponent implements OnInit {
	license: any;
	readonly logo: string;

	products: {name: string, expires: any}[] = [];

	constructor(
		private _shared: SharedService,
		private _config: AppConfigService
	) {
		this.logo = this._config.logo;
	}

	ngOnInit() {
		this._shared
			.getLicenseInfo()
			.subscribe(license => {
				this.license = license;
				const prods = this.toList(license.products);
				this.products = prods.map(it => {

					const split = it.split(':');
					return {name: split[0], expires: split[1]};
				})
			});
	}

	toList(txt: string = ''): string[] {
		if (txt === null || txt === '' || txt.split(',').length === 1)
			return Array.of(txt);
		return Array.from(txt.split(','));
	}

	phone(contact: string) {
		return contact.substr(0, 15);
	}
}
