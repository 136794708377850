import { BrowserModule, Title } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { WorkflowModule } from "./workflow/workflow.module";
import { SettingModule } from "./setting/setting.module";
import { SchedulerModule } from "./scheduler/scheduler.module";
import { MAT_CARD_CONFIG, MatCardModule } from '@angular/material/card';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
} from "@angular/material/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
} from "@angular/common/http";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { JwtModule } from "@auth0/angular-jwt";
import { PatientModule } from "./patient/patient.module";
import { ReportingModule } from "./reporting/reporting.module";
import { StatisticModule } from "./statistic/statistic.module";
import { DynamicFormModule, FileService, FireSharedModule } from "./shared";
import { AuthGuard, AuthService, JwtConfig, LoginGuard } from "./auth";
import { PlatformModule } from "@angular/cdk/platform";
import { UserResolver } from "./user.resolver";
import numeral from "numeral";
import moment from "moment";
import { Moment } from "moment";
import { RouterExtService } from "./router-ext.service";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import localeNl from "@angular/common/locales/nl-BE";
import localeFrExtra from "@angular/common/locales/extra/fr";
import localeNlExtra from "@angular/common/locales/extra/nl-BE";
import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { FtPaginatorI18n } from "./paginator-i18n";
import { AppConfigService } from "./app-config.service";
import { MainComponent } from "./main.component";
import { MOMENT_FR_SPEC, NUMERAL_FR_SPEC } from "./utils";
import { MatBadgeModule } from "@angular/material/badge";
import { MessagingModule } from "./messaging";
import { CONFIG_URL } from "./urls";
import { TokenInterceptor } from "./auth/interceptors/token.interceptor";
import { PhysiciansModule } from "./physicians/physicians.module";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { FtErrorHandler } from "./ft-error-handler.service";
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from '@angular/material/expansion';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import {AdvancedSearchComponent} from "./shared/advanced-search/advanced-search.component";

registerLocaleData(localeFr, "fr-FR", localeFrExtra);
registerLocaleData(localeNl, "nl-NL", localeNlExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function paginatorI18n(
  translateService: TranslateService
): MatPaginatorIntl {
  return new FtPaginatorI18n(translateService).getPaginatorI18n();
}

export function ftDateFormats(_config: AppConfigService): MatDateFormats {
  return {
    parse: {
      dateInput: ["DDMMYYYY", "MMYYYY", "YYYY", "LL"],
    },
    display: {
      dateInput: _config.displayDateInput,
      monthYearLabel: "MM YYYY",
      dateA11yLabel: "LL",
      monthYearA11yLabel: "MM YYYY",
    },
  };
}

const translateConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient],
  },
};

@NgModule({
  declarations: [AppComponent, MainComponent],
    imports: [
        PlatformModule,
        BrowserModule,
        BrowserAnimationsModule,
        FireSharedModule,
        AppRoutingModule,
        MatCardModule,
        MatToolbarModule,
        ReactiveFormsModule,
        PatientModule,
        SchedulerModule,
        ReportingModule,
        MessagingModule,
        SettingModule,
        WorkflowModule,
        PhysiciansModule,
        StatisticModule,
        DynamicFormModule,
        MatMomentDateModule,
        MatTooltipModule,
        TranslateModule.forRoot(translateConfig),
        JwtModule.forRoot(JwtConfig),
        FormsModule,
        MatBadgeModule,
        AdvancedSearchComponent,
    ],
  providers: [
    Title,
    FileService,
    LoginGuard,
    AuthGuard,
    AuthService,
    UserResolver,
    RouterExtService,
    provideHttpClient(),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      deps: [AppConfigService, TranslateService],
      useFactory: (config: AppConfigService) => () => config.load(CONFIG_URL),
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      deps: [AppConfigService],
      useFactory: (config: AppConfigService) => config.appLocale,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      deps: [AppConfigService],
      useFactory: ftDateFormats,
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translate: TranslateService) => paginatorI18n(translate),
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "outline",
        subscriptSizing: "fixed",
        floatLabel: "auto",
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { horizontalPosition: "center" },
    },
    {
      provide: MAT_CARD_CONFIG,
      useValue: { appearance: 'outlined' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { maxHeight: '100vh', panelClass: 'ftx-dialog' },
    },
    { provide: ErrorHandler, useClass: FtErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private dateAdapter: DateAdapter<Moment>,
    matIconRegistry: MatIconRegistry,
    private _config: AppConfigService
  ) {
    this.dateAdapter.setLocale(this._config.appLocale);

    matIconRegistry.registerFontClassAlias("mdi", "mdi");

    numeral.register("locale", "fr", NUMERAL_FR_SPEC);
    moment.updateLocale("fr", MOMENT_FR_SPEC);
    moment.locale(this._config.appLang);

    numeral.locale(this._config.appLang);
  }
}
